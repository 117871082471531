<template>
  <PageContent
    class="fiche-licence-active"
    :prev-page="prevPage"
  >
    <template
      v-if="product"
      #header-right
    >
      <div class="flex-vcenter">
        <Badge
          v-if="badgeDispoInfo.variant && !computedIsProductAvailable"
          :variant="badgeDispoInfo.variant"
          :title="badgeDispoInfo.title"
          medium
        />
        <ButtonClassic
          v-else-if="product.nouvelle_version"
          balise-type="router-link"
          :label="$t('licence.nouvelle-version')"
          variant="ghost"
          color="secondary"
          icon="left"
          :router-link-obj="{ params: { id: product.nouvelle_version.id, }, }"
        >
          <template #left-icon>
            <IconNew />
          </template>
        </ButtonClassic>
        <ButtonClassic
          v-if="isProductAvailable(product)"
          id="button_voir_fiche"
          balise-type="router-link"
          :label="$t('licence.voir-la-fiche')"
          variant="ghost"
          color="primary"
          icon="left"
          :router-link-obj="{ name: 'catalogues_ressources_numeriques_item', params: { id: product.id, }, }"
        >
          <template #left-icon>
            <UilEye />
          </template>
        </ButtonClassic>
      </div>
      <ButtonGroup>
        <ButtonClassic
          variant="solid"
          icon="right"
          @click="copyToClipboard(`${currentUrl}?id_organisme=${organismeActuel.id}`)"
        >
          <template #right-icon>
            <UilShareAlt />
          </template>
        </ButtonClassic>
      </ButtonGroup>
    </template>

    <template
      v-if="product"
      #content
    >
      <LicenceOverview :product="product" />
      <div class="grid-zone">
        <div
          v-if="product.produit_remplacement"
          id="zone_produit_non_dispo"
        >
          <Toast
            v-bind="toastAttr"
            hide-close-btn
          />
          <ProductItem
            :product="product.produit_remplacement"
            view="list"
          />
        </div>
        <ManuelNumeriqueOffers
          v-if="!product.arrete"
          :product="product.produit_remplacement || product"
          :title="product.produit_remplacement
            ? $t('produit.detail-offres-pour-remplacement')
            : undefined
          "
          @add-to-list="checkToList"
        />
      </div>
      <div class="text-right">
        <ButtonClassic
          v-if="!isGE"
          v-tooltip="{
            content: $tc('general.mail-copie-info', 0, { mail: mailLde, } ),
            show: isDe,
            placement: 'top',
            delay: { show: 800, },
          }"
          v-bind="btnAssistanceAttr"
          variant="special"
          :label="$t('action.contactez-nous-pour-toute-question')"
          color="primary"
          icon="right"
          @click="copyMailLde()"
        >
          <template #right-icon>
            <UilComment />
          </template>
        </ButtonClassic>
      </div>
      <ProductRelations
        :products="similarProducts"
        :is-loading="similarProductsAreLoading"
      />
      <ModalListesSelection
        id="modal_listes_selection"
        :listes="activeLists"
        @open-creation-liste="$modal.show('modal_listes_creation')"
      />
    </template>
    <template
      v-else
      #content
    >
      <div>
        <InfiniteLoader />
      </div>
    </template>
  </PageContent>
</template>

<script>
import {
  PageContent,
  ButtonGroup,
  ButtonClassic,
  Toast,
  InfiniteLoader,
} from "@lde/core_lde_vue";

import Badge from "@/components/Badge.vue";
import LicenceOverview from "@/components/licences/LicenceOverview.vue";
import ManuelNumeriqueOffers from "@/components/manuels_numeriques/ManuelNumeriqueOffers.vue";
import ProductItem from "@/components/products/ProductItem.vue";
import ProductRelations from "@/components/products/ProductRelations.vue";
import IconNew from "@/components/icons/IconNew.vue";
import ModalListesSelection from "@/components/modals/ModalListesSelection.vue";

import mixinFicheProduit from "@/mixins/mixinFicheProduit";
import mixinDisponibilite from "@/mixins/mixinDisponibilite";

import { UilEye, UilShareAlt, UilComment } from "@iconscout/vue-unicons";

import Api from "@/modules/axios";
import { changePageTitle } from "@/modules/utils";
import config from "@/config";

import { mapGetters } from "vuex";

/**
 * Vue des infos à propos des licences d'une ressource numérique en particulier.
 */
export default {
  name: "FicheLicence",
  components: {
    PageContent,
    ButtonGroup,
    ButtonClassic,
    Toast,
    Badge,
    LicenceOverview,
    ManuelNumeriqueOffers,
    ProductItem,
    ProductRelations,
    ModalListesSelection,
    InfiniteLoader,
    IconNew,
    UilEye,
    UilShareAlt,
    UilComment,
  },
  mixins: [mixinFicheProduit, mixinDisponibilite],
  beforeRouteEnter(to, from, next) {
    next((vm) => {
      vm.prevPage = from;
    });
  },
  data() {
    return {
      prevPage: null,
      currentUrl: window.location.href,
      // TODO: Mettre null quand on aura le back
      similarProducts: [],
      similarProductsAreLoading: false,
      controller: new AbortController(),
    };
  },
  computed: {
    ...mapGetters(["isGE", "organismeActuel", "allLists", "currentList", "urlHotline", "headerLists"]),
    urlHotlineTicket() {
      return config.url.hotlineTicket;
    },
    toastAttr() {
      if (this.product.arrete) {
        return {
          title: this.$t("produit.reference-arretee"),
          content: this.$t("produit.nous-pouvons-proposer-ref-alternative"),
          variant: "error",
        };
      }
      return {
        title: this.$t("produit.reference-remplacee-par-nouvelle-version"),
        content: this.$t("produit.vous-pouvez-proceder-remplacement"),
        variant: "warning",
      };
    },
    isDe() {
      return this.country === "de";
    },
    btnAssistanceAttr() {
      const attr = {};
      if (!this.isDe) {
        attr.baliseType = "a";
        attr.extLink = this.urlHotline;
      }
      return attr;
    },
    mailLde() {
      return config.mails.lde;
    },
  },
  beforeDestroy() {
    this.controller.abort();
  },
  methods: {
    /**
     * Récupère les infos de la licence.
     * @returns {Promise} Infos du produit.
     */
    fetchProduct() {
      return Api().get(
        `/manuel_numerique/${this.$route.params.id}/licences_detail/`,
        { signal: this.controller.signal },
      )
        .then(({ data }) => {
          if (this.controller.signal.aborted) {
            return false;
          }

          this.product = data;
          this.product.offres.forEach((element) => {
            element.quantite = element.quantite_min || 1;
          });
          if (this.product.offre_defaut) {
            this.product.offre_defaut.quantite = this.product.offre_defaut.quantite_min || 1;
          }

          // On crée un objet d'offres groupées sur le champ licence
          this.product.offres_group = data.offres.reduce((r, a) => {
            r[a.licence] = r[a.licence] || [];
            r[a.licence].push(a);
            return r;
          }, Object.create(null));

          if (this.$route.hash) {
            setTimeout(() => { window.location.href = this.$route.hash; }, 1);
          }

          changePageTitle(this.product.libelle, this.$route);
          this.fetchSimilarArticles();
          return true;
        });
      // =====
    },
    /**
     * Récupère les articles de même collection et ceux de même éditeur.
     */
    fetchSimilarArticles() {
      // TODO: Cyril, à adapter après le back mis en place...
      this.similarProductsAreLoading = true;
      return Api().get(`/manuel_numerique/${this.product.id}/related/`)
        .then((res) => {
          this.similarProducts = res.data;
          this.similarProductsAreLoading = false;
        });
    },
    customCopyToClipboard(text, title = null) {
      const el = document.createElement("textarea");
      el.value = text;
      document.body.appendChild(el);
      el.select();
      document.execCommand("copy");
      document.body.removeChild(el);

      this.$toast.success({ title: title || this.$t("toast.element-copie-presse-papier") });
    },
    copyMailLde() {
      if (this.isDe) {
        this.customCopyToClipboard(
          this.mailLde, this.$tc("general.email-copiee", 0, { mail: this.mailLde }),
        );
      }
    },
  },
};
</script>

<style lang="scss">
@use "~@lde/core_lde_vue/dist/assets/styles/_variables.scss" as var;

.fiche-licence-active {
  #button_voir_fiche { margin-left: var.$space-x-tiny; }

  #zone_produit_non_dispo { grid-column: 2 / -2; }

  .toast { margin-bottom: var.$space-tiny; }
}
</style>
